html, body {
    width: auto;
    height: auto;
    min-height: auto;
}

@media only screen and (min-width:768px) {
    html, body {
        min-width: 768px;
    }
}