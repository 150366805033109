@media (max-width: 991px) {
  .image-cropper {
    width: 500px !important;
    height: 500px !important;
  }
}

@media (max-width: 700px) {
  .image-cropper {
    width: 400px !important;
    height: 400px !important;
  }
}

@media (max-width: 550px) {
  .image-cropper {
    width: 300px !important;
    height: 300px !important;
  }
}