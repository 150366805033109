html,
body {
  overflow-x: hidden;
  margin: 0;
  font-family: 'Roboto', BlinkMacSystemFont, 'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media only screen and (min-width: 768px) {
  html,
  body {
    min-width: 768px;
    background: #f4f4f4;
  }
}

/* body.stop-scrolling {
  height: 100%;
  overflow: hidden;
} */

input[type='search']::-webkit-search-cancel-button {
  display: hidden;
  size: 50px;
}

.hideScrollBar {
  overflow: auto; /* Allow scrolling */
  scrollbar-width: none; /* Hide scrollbar for Firefox */
  -ms-overflow-style: none; /* Hide scrollbar for Internet Explorer and Edge */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hideScrollBar::-webkit-scrollbar {
  display: none;
}

/* RichTextEditor.css */
.wrapperClassName {
  border: 1px solid #e5e7eb;
  border-radius: 0.375rem;
}

.toolbarClassName {
  border-bottom: 1px solid #e5e7eb;
  padding: 0.5rem;
}

.editorClassName {
  min-height: 150px;
  padding: 1rem;
  border: 1px solid #e5e7eb;
  border-radius: 0.375rem;
  outline: none;
}

.bot-widget-bubble.bot-elements--right {
  left: 20px;
}
