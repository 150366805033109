.option input[type='checkbox'] {
  visibility: hidden;
}

.option {
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 12px;
}
.option  .customcheckbox {
  position: absolute;
  top: 0;
  left: 0;
  height: 40px;
  width: 40px;
  border: 1px solid #000000;
  border-radius: 5px;
}

.option input:checked ~ .customcheckbox {
  background-color: #ffffff;
}
.customcheckbox:after {
  content: '';
  position: absolute;
  display: none;
}
.option input:checked ~ .customcheckbox:after {
  display: block;
}

.option .customcheckbox:after {
  left: 35%;
  bottom: 30%;
  width: 14px;
  height: 22px;
  border: solid black;
  border-radius: 2px;
  border-width: 0 6px 6px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
