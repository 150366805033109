.cropper-container {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1000;

  .ignore {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: fixed;
    background: #000000eb;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1000;

    .image-cropper {
      position: relative;
      width: calc(100vh - 250px);
      height: calc(100vh - 250px);
      z-index: 999999;

      div:first-child {
        width: 100%;
        display: block;
        margin-right: 20px;
        border: 2px solid white;
        border-radius: 4px;
        box-shadow: 3px 12px 4px 7px rgba(0, 0, 0, 0.13);

        img {
          background-color: #ffffff;
        }
      }

      .ReactCrop {
        width: 100%;
        height: 100%;
        display: block;
      }

      .ReactCrop__image {
        width: 100% !important;
        height: 100% !important;
        border: none !important;
        object-fit: contain !important;
      }

      .cropper-actions {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        position: absolute;
        bottom: -38px;

        button {
          display: flex;
          justify-content: center;
          align-items: center;
          background: white;
          border-radius: 4px;
          padding: 8px;
          font-weight: bold;
          box-shadow: 0 2px 2px 0 rgba(54, 93, 230, 0.2);

          &.confirm {
            width: auto;
            margin-right: 8px;
            color: #365de6;

            &:hover {
              background: #365de6;
              color: white;
            }
          }

          &.cancel {
            width: auto;
            color: red;

            &:hover {
              background: red;
              color: white;
            }
          }
        }
      }
    }
  }
}
