.navbarAnimation {
  animation-name: slideDown;
  animation-duration: 0.5s;
  animation-timing-function: ease-in-out;
  animation-delay: 0.1s;
  animation-fill-mode: both;
}

.loader {
  /* border: 8px solid #008000 !important; */
  border-top: 1px solid #fbc02d !important;
  border-radius: 50% !important;
  /* width: 120px;
    height: 120px; */
  animation: spin 2s linear infinite !important;
}

.tooltiper {
  perspective: 600px;
  transform-style: preserve-3d;
}

.tooltiper .tooltip {
  min-width: 100px;
  width: max-content;
  max-width: 350px;
  position: absolute;
  font-size: 0.7rem;
  text-align: left;
  padding: 5px 10px;
  border-radius: 5px;
  display: block;
  /* top: -20; */
  left: 0;
  line-height: 1.4em;
  transform: rotateX(90deg) translateZ(0px);
  opacity: 0;
  transition: transform 0.4s cubic-bezier(0.64, 0.57, 0.67, 1.53), opacity 0.1s ease-out 0.1s;
}

.tooltiper:hover .tooltip {
  transform: rotateX(0) translateZ(20px);
  opacity: 0.9;
  transition: transform 0.4s cubic-bezier(0.64, 0.57, 0.67, 1.53), opacity 0.1s ease-out;
}

.tooltiper .tooltip:after {
  content: '';
  position: absolute;
  left: 20px;
  top: -7px;
  height: 0;
  border-style: solid;
  border-width: 0 7px 7px 7px;
  border-color: transparent transparent #fbc02d transparent;
}

.bigBrollyAnimation {
  padding: 5px;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  transform-origin: bottom;
  animation-name: bounce-7;
  animation-timing-function: cubic-bezier(0.28, 0.84, 0.42, 1);
}

/* TABLE */
.border-collapse {
  border-collapse: inherit;
}
/* END TABLE */

/* STEPPER */
.stepperItem::after {
  position: absolute;
  right: 0;
  top: 0;
  margin-right: -50px;
  width: 0;
  height: 0;
  content: '';
  border-top: 50px solid transparent;
  border-bottom: 50px solid transparent;
  border-left: 50px solid green;
  transition: all 900ms ease-in-out;
}
/* END STEPPER */

/* .masking {
    -webkit-mask-image: url(w3logo.png);
    mask-image: url(w3logo.png);
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat; 
} */

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes slideDown {
  from {
    top: -80px;
  }
  to {
    top: 0;
  }
}

@keyframes bounce-7 {
  0% {
    transform: scale(1, 1) translateY(0);
  }
  10% {
    transform: scale(1.1, 0.9) translateY(0);
  }
  30% {
    transform: scale(0.9, 1.1) translateY(-100px);
  }
  50% {
    transform: scale(1.05, 0.95) translateY(0);
  }
  57% {
    transform: scale(1, 1) translateY(-7px);
  }
  64% {
    transform: scale(1, 1) translateY(0);
  }
  100% {
    transform: scale(1, 1) translateY(0);
  }
}

.main__loading {
  background: #365de6;
  display: flex;
  height: 4px;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 999999;

  .bar {
    content: '';
    display: inline;
    width: 0;
    height: 100%;
    left: 50%;
    text-align: center;
  }

  .bar:nth-child(1) {
    background-color: #da4733;
    animation: loading 3s linear infinite;
  }

  .bar:nth-child(2) {
    background-color: #4ee74e;
    animation: loading 3s linear 1s infinite;
  }

  .bar:nth-child(3) {
    background-color: #fdba2c;
    animation: loading 3s linear 2s infinite;
  }

  @keyframes loading {
    from {
      left: 50%;
      width: 0;
      z-index: 100;
    }

    33.3333% {
      left: 0;
      width: 100%;
      z-index: 10;
    }

    to {
      left: 0;
      width: 100%;
    }
  }
}
