html, body {
    width: 100vw;
    height: 100vh;
    min-height: 100vh;
}



@media only screen and (min-width:768px) {
    html, body {
        min-width: 768px;
    }
}

.mainBg {
    background-image: url("../../public/img/bgCar.png");
    background-color: #FBC02D;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-clip: border-box;
    background-attachment: scroll;
    height: 100vh;
    width: 100vw;
}
