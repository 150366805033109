.main__loading {
    background: #365de6;
    display: flex;
    height: 4px;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 999999;

    .bar {
        content: "";
        display: inline;
        width: 0;
        height: 100%;
        left: 50%;
        text-align: center;
    }

    .bar:nth-child(1) {
        background-color: #da4733;
        animation: loading 3s linear infinite;
    }

    .bar:nth-child(2) {
        background-color: #4ee74e;
        animation: loading 3s linear 1s infinite;
    }

    .bar:nth-child(3) {
        background-color: #fdba2c;
        animation: loading 3s linear 2s infinite;
    }

    @keyframes loading {
        from {
            left: 50%;
            width: 0;
            z-index: 100;
        }

        33.3333% {
            left: 0;
            width: 100%;
            z-index: 10;
        }

        to {
            left: 0;
            width: 100%;
        }
    }
}

