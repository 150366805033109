
@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family:  'Roboto', BlinkMacSystemFont, 'Segoe UI', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}


.divider:not(:empty) {
  gap: 1rem;
}

.divider {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
  margin-top: 1rem;
  margin-bottom: 1rem;
  height: 1rem;
  white-space: nowrap;
}

.divider:before,
.divider:after {
  background-color: rgba(128, 128, 128, 0.15);
}

.divider:before,
.divider:after,
.divider:before,
.divider:after {
  content: '';
  flex-grow: 1;
  height: 0.125rem;
  width: 100%;
}
